import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { initConfig } from './config';
import * as serviceWorker from './serviceWorker';

import './index.css';

let timer: NodeJS.Timeout;

type TokensType = {
    token: string;
    refreshToken: string;
};

type MessageType = {
    source: 'tokens';
    payload: TokensType;
};
declare global {
    interface Window {
        adminTokens: TokensType;
    }
}

function receiveMessage(event: MessageEvent) {
    const message = event.data as MessageType;
    if (message && message.source && message.source === 'tokens') {
        window.adminTokens = message.payload;
    }
}

window.addEventListener('message', receiveMessage, false);

if (process.env.NODE_ENV === 'production') console.log = () => {};

const checkAdmin = () => {
    const referrerUrl = document.referrer || window.location.href;
    const url = new URL(referrerUrl);
    const isAdmin = url.searchParams.get('isAdmin');
    if (isAdmin) {
        return !!window.adminTokens;
    }

    return true;
};

//@ts-ignore
if (window.env) {
    initConfig();
    ReactDOM.render(<App />, document.getElementById('root'));
} else {
    timer = setInterval(() => {
        //@ts-ignore
        if (window.env && checkAdmin()) {
            initConfig();
            if (!!window.adminTokens) {
                window.removeEventListener('message', receiveMessage);
                clearInterval(timer);
            }
            ReactDOM.render(<App />, document.getElementById('root'));
        }
    }, 200);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
