import * as Cookies from 'js-cookie';
import { config as AppConfig } from '../config';
import jwtDecode from 'jwt-decode';
import { logoutAPI } from './customFetch';

export interface IAuthServiceInitParams {
    logoutEvent: () => void;
}

export type UserRole =
    | 'ROLE_USER'
    | 'ROLE_ADMIN'
    | 'ROLE_CLIENT'
    | 'ANONYMOUS_AGGREGATOR_CLIENT'
    | 'ROLE_AGGREGATOR_USER';

export type TokenPayload = {
    iat: number;
    roles: Array<UserRole>;
    username: string;
    exp: number;
    temp: boolean;
    uuid: string;
    first_name: string | null;
    middle_name: string | null;
    family_name: string | null;
    partner_id?: string;
};

export interface IPermissionEntity {
    object: number;
    operation: string;
    permitted: boolean;
}

export interface IPermissionClass {
    location: number;
    class: string;
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    __typename?: string;
}

export declare type AuthRequest = (
    input?: Request | string,
    init?: RequestInit,
    wasRefreshed?: boolean,
) => Promise<Response>;

interface IAuthService {
    readonly isLoggedIn: () => boolean;
    readonly signHeaders: (
        headers: Record<string, string>,
        token?: string | null,
    ) => Record<string, string>;
    readonly setAccessToken: (token: string, refresh_token: string, remember: boolean) => void;
    readonly logout: (redirectLoginForm?: boolean) => void;
    readonly getUserId: () => string | null;
    readonly refreshToken: () => Promise<string>;
    readonly enrichToken: (url: string) => Promise<string>;
    readonly decodeToken: (token: string) => TokenPayload;
    readonly authHeaderKey: string;
    readonly getUserInfo: () => TokenPayload | null;
    readonly getToken: () => string | undefined;
    readonly checkRoles: (roles: UserRole[], token?: string) => boolean;
    readonly userIsAdmin: (token?: string) => boolean;
}

const getToken = (): string | undefined => {
    const adminToken = window?.adminTokens?.token;
    const getParams = new URLSearchParams(window.location.search);
    const token = getParams.get(`token`);

    return (
        adminToken ??
        (token ||
            localStorage.getItem(AppConfig.tokenStoreKey) ||
            Cookies.get(AppConfig.tokenStoreKey))
    );
};
const getRefreshToken = (): string | undefined =>
    localStorage.getItem(AppConfig.refreshTokenStoreKey) ||
    Cookies.get(AppConfig.refreshTokenStoreKey);

const checkRememberMe = (): boolean => Boolean(localStorage.getItem(AppConfig.tokenStoreKey));

const isLoggedIn = (): boolean => Boolean(getToken());

const logout = (redirectLoginForm = true): void => {
    logoutAPI();
    Cookies.remove(AppConfig.tokenStoreKey);
    Cookies.remove(AppConfig.refreshTokenStoreKey);
    localStorage.removeItem(AppConfig.tokenStoreKey);
    localStorage.removeItem(AppConfig.refreshTokenStoreKey);
    if (redirectLoginForm) {
        window.location.replace('/login');
    }
};

const userIsAdmin = (token?: string): boolean => {
    return checkRoles(['ROLE_ADMIN'], token);
};

const checkRoles = (roles: UserRole[], token?: string): boolean => {
    let user: TokenPayload | null;

    if (token) {
        user = decodeToken(token);
    } else {
        user = getUserInfo();
        if (!user) throw 'user must be logged';
    }

    const res = roles.filter(role => ~user!.roles.indexOf(role));
    return res.length === roles.length;
};

const signHeaders = (
    headers: Record<string, string> = {},
    token?: string | null,
): Record<string, string> => {
    if (token) {
        return { ...headers, Authorization: 'Bearer ' + token };
    }
    return { ...headers, Authorization: 'Bearer ' + getToken() };
};

const getUserId = (): string | null => {
    const token = getToken();
    if (token) {
        return AuthService.decodeToken(token).uuid;
    }
    AuthService.logout();
    return null;
};

const getUserInfo = (): TokenPayload | null => {
    const token = getToken();
    if (token) {
        return AuthService.decodeToken(token);
    }
    AuthService.logout();
    return null;
};

const setAccessToken = (token: string, refresh_token?: string, remember: boolean = false): void => {
    if (remember) {
        localStorage.setItem(AppConfig.tokenStoreKey, token);
        if (refresh_token) {
            localStorage.setItem(AppConfig.refreshTokenStoreKey, refresh_token);
        }
    } else {
        //TODO: add secure
        Cookies.set(AppConfig.tokenStoreKey, token);
        if (refresh_token) {
            Cookies.set(AppConfig.refreshTokenStoreKey, refresh_token);
        }
    }
};

const refreshToken = async (): Promise<string> => {
    const refreshToken = getRefreshToken();

    if (!refreshToken) throw Error('Access error');
    if (decodeToken(getToken()!).temp) {
        throw Error("Temp token couldn't refreshed");
    }

    const request = await fetch(AppConfig.api.auth.refreshTokenUrl, {
        body: JSON.stringify({
            refresh_token: refreshToken,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
    });

    if (request.status === 200) {
        const response = await request.json();
        //console.log(response);
        const { token, refresh_token } = response;
        AuthService.setAccessToken(token, refresh_token, checkRememberMe());
        //console.log('token was refreshed');
        return token;
    }

    //console.log('refresh token error', request);
    throw Error('Access error');
};

const enrichToken = async (enrichUrl: string, repeat?: boolean): Promise<string> => {
    const refreshToken = getRefreshToken();

    //if(!refreshToken) throw Error("Refresh tocken is empty");

    if (decodeToken(getToken()!).temp) {
        throw Error("Temp token couldn't enriched");
    }

    const request = await fetch(enrichUrl, {
        body: JSON.stringify({}),
        headers: signHeaders({ 'Content-Type': 'application/json' }),
        method: 'post',
    });

    if (request.status >= 200 && request.status < 300) {
        const response = await request.json();
        //console.log(response);
        const { token } = response;
        AuthService.setAccessToken(token, refreshToken || '', checkRememberMe());
        //console.log('token was refreshed');
        return token;
    }

    if (request.status === 401 && !repeat) {
        await AuthService.refreshToken();
        return enrichToken(enrichUrl, true);
    } else {
        logout();
    }

    //console.log('refresh token error', request);
    throw Error('Enrich error');
};

const decodeToken = (token: string): TokenPayload => {
    return jwtDecode<TokenPayload>(token);
};

const AuthService: IAuthService = {
    isLoggedIn,
    signHeaders,
    setAccessToken,
    logout,
    getUserId,
    refreshToken,
    decodeToken,
    authHeaderKey: 'Authorization',
    getUserInfo,
    getToken,
    enrichToken,
    userIsAdmin,
    checkRoles,
};

export default AuthService;
